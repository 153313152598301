import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/global-state';

export const selectContentCards = ({ loyalty }: RootState) => loyalty.contentCards.contentCards;

export const selectUnreadContentCards = ({ loyalty }: RootState) =>
  loyalty.contentCards.unreadContentCards;

export const selectWebContentCards = ({ loyalty }: RootState) =>
  loyalty.contentCards.webContentCards;

export const selectOpenInbox = ({ loyalty }: RootState) => loyalty.contentCards.openInbox;

export const selectBrazeContentCards = createSelector(
  selectContentCards,
  selectUnreadContentCards,
  selectWebContentCards,
  selectOpenInbox,
  (contentCards, webContentCards, unreadContentCards, openInbox) => {
    return {
      contentCards: contentCards || [],
      webContentCards: webContentCards || [],
      unreadContentCards,
      openInbox,
    };
  }
);
