import { ContentCard } from 'utils/braze';

type ToggleContentCards = <E extends Element>(p: E) => void;

export type WebContentCard = {
  id: string;
  title: string | null;
  description: string | null;
  url: string | null;
  aspectRatio: number;
  viewed: boolean;
  imageUrl: string | null;
  created: string;
  updated: string;
  categories: string[];
  expiresAt: string;
  linkText: string | null;
  extras: Record<string, string>;
  pinned: boolean;
  dismissible: boolean;
  dismissed: boolean;
  clicked: boolean;
  isControl: boolean;
  test: boolean;
  la: string | null;
  le: string | null;
  ic: string;
  lc: boolean;
};

export const ContentCardTypes = {
  CLASSIC: 'Classic',
  IMAGE_ONLY: 'ImageOnly',
  CAPTIONED: 'Captioned',
} as const;

export const CUSTOM_ATTRIBUTE = {
  UNREAD_CONTENT_CARDS: 'unread_content_cards',
};
export interface IBrazeContext {
  initBraze: () => void;
  getCachedContentCards(): Promise<ContentCard[]>;
  setUserId(userId: string | null): void;
  toggleContentCards: ToggleContentCards;
  isInAppMessageOpen: boolean;
  setIsInAppMessageOpen: (value: boolean) => void;
  logContentCardImpression(id: string): void;
  logContentCardClicked(card: ContentCard): void;
}
