import { FC, PropsWithChildren, useState } from 'react';

import { ContentCard } from '@braze/react-native-sdk';

import { QuestDetailModal } from 'components/quests/quest-detail-modal';
import { QuestTerms } from 'components/quests/quest-drawer-content/quest-terms';
import { useUserQuest } from 'components/quests/use-user-quest';
import { ContentCardTypes } from 'state/braze/types';

import { CaptionedContentCard } from './captioned';

type ChallengeContentCardProps = {
  card: ContentCard & {
    type: typeof ContentCardTypes.CAPTIONED;
  };
};

export const ChallengeContentCard: FC<PropsWithChildren<ChallengeContentCardProps>> = ({
  card,
}) => {
  const { userQuest } = useUserQuest();
  const [showQuestTerms, setShowQuestTerms] = useState(false);

  return (
    <>
      <CaptionedContentCard
        card={card}
        showTermsLink={!!userQuest}
        onShowTerms={() => setShowQuestTerms(true)}
      />
      {showQuestTerms && (
        <QuestDetailModal
          title=""
          hideTitle
          onDismiss={() => {
            setShowQuestTerms(false);
          }}
        >
          <QuestTerms cmsQuestTerms={userQuest?.cmsQuestCard.moreInfo} />
        </QuestDetailModal>
      )}
    </>
  );
};
