import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';

import { HamburgerButton } from 'components/app-header/mobile-header/hamburger-button';
import {
  Header,
  LinkNoDecoration,
  MenuButtonContainer,
  MobileHeaderContainer,
} from 'components/app-header/styled';
import {
  NO_NETWORK_BACKGROUND_COLOR,
  NoNetworkHeader,
} from 'components/error-network/no-network-header';
import { LayoutContainer } from 'components/layout/styled';
import { Logo } from 'components/logo';
import { NotificationsButton } from 'components/notifications-button';
import { TopOrderStatusBar } from 'components/top-order-status-bar';
import { useTopOrderStatusBar } from 'components/top-order-status-bar/use-top-order-status-bar';
import { TopServiceMode } from 'components/top-service-mode';
import { useContentCardsNotificationsExperiment } from 'experiments/use-content-cards-notifications';
import { useMenuTopServiceMode } from 'experiments/use-menu-top-service-mode-header';
import { useRoute } from 'hooks/navigation/use-route';
import { useIsDesktopBreakpoint, useIsMobileBreakpoint } from 'hooks/use-media-query';
import { useAuthContext } from 'state/auth';
import useAuthFlow from 'state/auth/hooks/use-auth-flow';
import {
  MobileHeaderNavContext,
  useMobileHeaderBackButton,
  useMobileHeaderComponent,
} from 'state/mobile-header-nav';
import { useNetworkContext } from 'state/network';
import { useReversedUIContext } from 'state/reversed-ui';
import { useStoreContext } from 'state/store';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';
import { routes } from 'utils/routing';

import SignupMobileHeader from '../signup-mobile-header';
import {
  HeaderContainer,
  StyledCenterSpacer,
  StyledLogo,
  StyledRightSpacer,
  TopServiceModeContainer,
} from '../styled';

import { CartButton } from './cart-button';
import { MobileHeaderCta } from './mobile-header-cta';

export const StandardMobileHeader = () => {
  const enableContentCards = useContentCardsNotificationsExperiment(true);
  const { noStoreSelected } = useStoreContext();
  const { hideHeaderExceptNavigation } = useContext(MobileHeaderNavContext);
  const isDesktop = useIsDesktopBreakpoint();
  const BackButtonComponent = useMobileHeaderBackButton();
  const { isNetworkConnected, isInternetReachable } = useNetworkContext();
  const { pathname } = useRoute();

  // Menu Top Service Mode Experiment
  const { evaluateMenuTopServiceModeExperiment } = useMenuTopServiceMode();
  const [experimentResult, setExperimentResult] = useState(false);
  const [isExperimentEvaluated, setIsExperimentEvaluated] = useState(false);

  const topOrderStatusBarData = useTopOrderStatusBar();
  const showTopOrderStatusBar = !!topOrderStatusBarData.heading;

  const isRewardsPage = pathname === routes.rewards || pathname === routes.rewardsList;
  const isOfferPage = pathname === routes.rewards + routes.offers;
  const isMyCodePage = pathname === routes.redeem;

  useEffect(() => {
    const result = evaluateMenuTopServiceModeExperiment();
    setExperimentResult(result);
    setIsExperimentEvaluated(true);
  }, [evaluateMenuTopServiceModeExperiment]);

  const showTopServiceModeBar = useMemo(() => {
    if (!isExperimentEvaluated) {
      return false;
    }
    return experimentResult || isRewardsPage || isOfferPage || isMyCodePage;
  }, [isExperimentEvaluated, experimentResult, isRewardsPage, isOfferPage, isMyCodePage]);

  const { formatMessage } = useIntl();
  const { reversedUI } = useReversedUIContext();

  const isMobile = useIsMobileBreakpoint();

  const { isInAuthFlow } = useAuthFlow();
  const { modalAuthIsOpen } = useAuthContext();

  const shouldHideHeader =
    hideHeaderExceptNavigation ||
    (pathname &&
      (pathname.includes(formatMessage({ id: 'routes.account' })) ||
        pathname.includes(routes.communication) ||
        pathname.includes(routes.support)));

  if (isInAuthFlow && !modalAuthIsOpen) {
    return <SignupMobileHeader />;
  }

  const LeftSideComponent = BackButtonComponent || HamburgerButton;

  const normalBackground = reversedUI
    ? theme.token('background-hero-reversed')
    : theme.token('background-hero-light');

  const showNoNetworkHeader = !isNetworkConnected || !isInternetReachable;

  return (
    <MobileHeaderContainer
      accessibilityLabel={formatMessage({ id: 'primaryNavLinks' })}
      testID="mobile-header"
      background={showNoNetworkHeader && isMobile ? NO_NETWORK_BACKGROUND_COLOR : normalBackground}
      hideShadow={!!shouldHideHeader}
    >
      {showNoNetworkHeader && <NoNetworkHeader />}
      <LayoutContainer background={normalBackground}>
        <HeaderContainer reversed={reversedUI}>
          <Header>
            <MenuButtonContainer>
              <LeftSideComponent />
            </MenuButtonContainer>
            {!shouldHideHeader && (
              <>
                <StyledCenterSpacer>
                  <LinkNoDecoration
                    to={routes.base}
                    alignSelf={'flex-start'}
                    marginLeft={isDesktop ? 'auto' : '$8'}
                  >
                    <StyledLogo testID="mobile-header-logo">
                      <Logo />
                    </StyledLogo>
                  </LinkNoDecoration>
                </StyledCenterSpacer>
                <StyledRightSpacer>
                  <MobileHeaderCta />
                  {enableContentCards && <NotificationsButton />}
                  <CartButton />
                </StyledRightSpacer>
              </>
            )}
          </Header>
        </HeaderContainer>
      </LayoutContainer>
      <TopServiceModeContainer>
        <LayoutContainer
          background={showTopOrderStatusBar ? primitive.bk.$crunchyGreen : primitive.$white}
        >
          {topOrderStatusBarData.queryWasExecuted &&
            (showTopOrderStatusBar ? (
              <TopOrderStatusBar data={topOrderStatusBarData} />
            ) : (
              !noStoreSelected && (!isMobile || showTopServiceModeBar) && <TopServiceMode />
            ))}
        </LayoutContainer>
      </TopServiceModeContainer>
    </MobileHeaderContainer>
  );
};

export default function MobileHeader() {
  const HeaderComponent = useMobileHeaderComponent();

  return HeaderComponent ? <HeaderComponent /> : <StandardMobileHeader />;
}
