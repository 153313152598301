import { ReactNode } from 'react';

import { isUndefined, merge } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import {
  IBackendCartEntries,
  IBaseItem,
  ICartEntry,
  ICombo,
  IComboSlot,
  IItem,
  IItemOption,
  IItemOptionModifierWithQuantity,
  IModifierSelection,
  IPicker,
  ISanityItemOptionModifier,
  ISanityVendorConfigs,
  IVendorConfig,
  IWithMenuObjectSettings,
} from '@rbi-ctg/menu';
import { IRestaurant } from '@rbi-ctg/store';
import { MenuObjectTypes } from 'enums/menu';
import {
  Brand,
  CartEntryType as CartEntryTypeGateway,
  IPriceOrderInput,
  PosVendor,
} from 'generated/graphql-gateway';
import { IPriceOrderParams, WISanityImage } from 'hooks/price-order/types';
import { LoyaltyOffer, LoyaltyReward } from 'state/loyalty/types';
import { ServiceMode } from 'state/order';
import { clamp } from 'utils/calc';
import { buildStoreAddress } from 'utils/cart';
import { platform } from 'utils/environment';
import { getType } from 'utils/menu';
import { getDefaultComboSlotOption } from 'utils/menu/get-default-combo-slot-option';
import { makeItemOptionModifier } from 'utils/menu/modifiers';
import { priceForCartEntry } from 'utils/menu/price';
import { getFirstStringInLocaleBlockContent } from 'utils/sanity';

import { PluTypes, PosVendors } from '../vendor-config';

import {
  CartEntryType,
  ComboSlotUiPattern,
  IComboCartEntryOptions,
  IComboSlotCartEntryOptions,
  ICreateCartEntryOptions,
  IItemCartEntryOptions,
  IItemOptionCartEntryOptions,
  IItemOptionModifierCartEntryOptions,
  IOfferCartEntryOptions,
} from './types';

export * from './types';

// This number 9 doesn’t come from Sanity, this is cross brand based on POS limitations on double digit numbers.
// https://rbictg.atlassian.net/browse/CBA-1032
export const MAX_CART_QUANTITY = 9;
export const MIN_CART_QUANTITY = 1;

// If no sanity max amount is configured we use this value to prevent unexpected amount of quantity for same item.
export const MAX_ADD_ON_QUANTITY = 6;

const clampCartQuantity = clamp(MIN_CART_QUANTITY, MAX_CART_QUANTITY);

export const getMenuObjectLimitPerOrder = (
  menuObject: IWithMenuObjectSettings
): number | undefined => menuObject.menuObjectSettings?.limitPerOrder;

export const getMenuObjectQuantityInCart = (cartEntries?: ICartEntry[], menuObjectId?: string) => {
  if (!cartEntries?.length || !menuObjectId) {
    return 0;
  }
  return cartEntries.reduce(
    (sum, cartEntry) => (cartEntry._id === menuObjectId ? sum + cartEntry.quantity : sum),
    0
  );
};

export const getMenuObjectMaxCartQuantity = (menuObject: IWithMenuObjectSettings): number => {
  const limitPerOrder = getMenuObjectLimitPerOrder(menuObject);
  if (typeof limitPerOrder === 'number' && limitPerOrder >= MIN_CART_QUANTITY) {
    return clampCartQuantity(Math.floor(limitPerOrder));
  }
  return MAX_CART_QUANTITY;
};

export const getMenuObjectCartQuantity = ({
  menuObject,
  quantityToAdd = 1,
  cartEntries,
  cartIdEditing,
}: {
  menuObject?: IWithMenuObjectSettings | null;
  quantityToAdd?: number;
  cartEntries?: ICartEntry[];
  cartIdEditing?: string;
}): {
  availableCartQuantity: number;
  maxCartQuantity: number;
  maxCartQuantityMet: boolean;
} => {
  if (!menuObject) {
    return {
      availableCartQuantity: MAX_CART_QUANTITY,
      maxCartQuantity: MAX_CART_QUANTITY,
      maxCartQuantityMet: false,
    };
  }

  const entries = cartEntries?.filter(entry => entry.cartId !== cartIdEditing);
  const quantityOnCart = getMenuObjectQuantityInCart(entries, menuObject._id);
  const maxCartQuantity = getMenuObjectMaxCartQuantity(menuObject);
  const availableCartQuantity = maxCartQuantity - quantityOnCart;
  const maxCartQuantityMet = quantityToAdd > availableCartQuantity;

  return {
    availableCartQuantity,
    maxCartQuantity,
    maxCartQuantityMet,
  };
};

const StaticIgnoreConfig = Object.values(PosVendors).reduce(
  (acc, vendor) => ({
    ...acc,
    [vendor]: {
      pluType: PluTypes.IGNORE,
    },
  }),
  {}
);

export const sanitizeVendorConfigs = (
  vendorConfigs: ISanityVendorConfigs
): ISanityVendorConfigs => {
  const mappedVendorConfigs: ISanityVendorConfigs = {};
  Object.entries(vendorConfigs).forEach(([key, value]) => {
    const isVendorConfig = key !== 'type' && key !== '_type';
    const isEmpty = Boolean(value && !value?.pluType);
    if (isVendorConfig && isEmpty) {
      mappedVendorConfigs[key] = {
        ...(value as IVendorConfig),
        pluType: 'empty',
      };
    } else {
      mappedVendorConfigs[key] = value;
    }
  });
  return mappedVendorConfigs;
};

interface IBackendCartEntriesGateway extends Omit<IBackendCartEntries, 'children' | 'type'> {
  children: IBackendCartEntriesGateway[];
  type: CartEntryTypeGateway;
}

const cartEntryTypeToGatewayType = (type: CartEntryType): CartEntryTypeGateway => {
  switch (type) {
    case CartEntryType.combo:
      return CartEntryTypeGateway.COMBO;
    case CartEntryType.comboSlot:
      return CartEntryTypeGateway.COMBOSLOT;
    case CartEntryType.itemOption:
      return CartEntryTypeGateway.ITEMOPTION;
    case CartEntryType.itemOptionModifier:
      return CartEntryTypeGateway.ITEMOPTIONMODIFIER;
    case CartEntryType.offerCombo:
      return CartEntryTypeGateway.OFFERCOMBO;
    case CartEntryType.offerDiscount:
      return CartEntryTypeGateway.OFFERDISCOUNT;
    case CartEntryType.offerItem:
      return CartEntryTypeGateway.OFFERITEM;
    case CartEntryType.item:
    default:
      return CartEntryTypeGateway.ITEM;
  }
};

export const frontendToBackendCartEntryMap = ({
  cartId,
  children,
  pickerSelections,
  image,
  sanityId,
  _id, // eslint-disable-line @typescript-eslint/no-unused-vars
  uiPattern, // eslint-disable-line @typescript-eslint/no-unused-vars
  rewardEligible, // eslint-disable-line @typescript-eslint/no-unused-vars
  isUpsell, // eslint-disable-line @typescript-eslint/no-unused-vars
  reorder, // eslint-disable-line @typescript-eslint/no-unused-vars
  menuObjectSettings, // eslint-disable-line @typescript-eslint/no-unused-vars
  mainItem, // eslint-disable-line @typescript-eslint/no-unused-vars
  defaultComboSlotOption, // eslint-disable-line @typescript-eslint/no-unused-vars
  enableUpsellPricing, // eslint-disable-line @typescript-eslint/no-unused-vars
  nutritionWithModifiers, // eslint-disable-line @typescript-eslint/no-unused-vars
  modifierMultiplier, // eslint-disable-line @typescript-eslint/no-unused-vars
  vendorConfigs,
  offerVendorConfigs,
  type,
  ...rest
}: ICartEntry): IBackendCartEntriesGateway => {
  const remapped: IBackendCartEntriesGateway = {
    lineId: cartId,
    // Offer Cart Entries have a sanityId
    // Other entries do not
    sanityId: sanityId || _id,
    children: children.map(frontendToBackendCartEntryMap),
    image: JSON.stringify(image),
    vendorConfigs: vendorConfigs ? sanitizeVendorConfigs(vendorConfigs) : vendorConfigs,
    offerVendorConfigs: offerVendorConfigs
      ? sanitizeVendorConfigs(offerVendorConfigs)
      : offerVendorConfigs,
    type: cartEntryTypeToGatewayType(type),
    ...rest,
  };

  if (pickerSelections) {
    remapped.pickerSelections = JSON.stringify(pickerSelections);
  }

  return remapped;
};

export const remappedCartForBackEnd = (cart: ICartEntry[]): IBackendCartEntriesGateway[] => {
  return cart.map(frontendToBackendCartEntryMap);
};

const getCartEntryForCombo = (combo: ICombo, options?: IComboCartEntryOptions): ICartEntry => {
  const {
    cartId: existingCartId,
    comboSlotSelections = {},
    modifierSelections = [],
    pickerSelections = {},
    price,
    quantity,
    pathname: url = '',
    reorder,
    menuObjectSettings,
  } = options ?? {};
  const cartId = existingCartId || uuidv4();
  let children: ICartEntry[] = [];
  const comboSlots = Object.values(comboSlotSelections);

  if (combo.mainItem) {
    // modifiers with no combo slot id correspond to the main item
    const mainItemModifiers = modifierSelections.filter(({ comboSlotId }) =>
      isUndefined(comboSlotId)
    );

    children.push(
      createCartEntry({
        item: combo.mainItem,
        modifierSelections: mainItemModifiers,
      })
    );
  }

  children = [
    ...children,
    ...comboSlots.map(slot =>
      createCartEntry({
        // use the raw data so we have access to all the options, fallback to the selection data just in case(should never hit this case)...
        item: combo.options.find(comboSlot => comboSlot._id === slot.data._id) ?? slot.data,
        selections: slot.selections,
        modifierSelections: modifierSelections.filter(
          ({ comboSlotId }) => comboSlotId === slot.data._id
        ),
      })
    ),
  ];

  return {
    _id: combo._id,
    type: CartEntryType.combo,
    quantity: quantity || 1,
    sanityId: '',
    url,
    name: combo.name.locale,
    vendorConfigs: combo.vendorConfigs,
    image: combo.image,
    cartId,
    children,
    pickerSelections,
    menuObjectSettings: combo.menuObjectSettings || menuObjectSettings,
    mainItem: combo.mainItem,
    ...(reorder && { reorder }),
    ...(price !== undefined && !isNaN(price) && { price }),
  };
};

const getCartEntryForComboSlot = (
  comboSlot: IComboSlot,
  options?: IComboSlotCartEntryOptions
): ICartEntry => {
  const { cartId: existingCartId, selections, pathname: url = '', modifierSelections = [] } =
    options ?? {};
  const cartId = existingCartId || uuidv4();
  let children: ICartEntry[] = []; // Is this being used at all???

  children = [
    ...children, // Is this being used at all???
    ...(selections || []).map(selection =>
      createCartEntry({
        item: selection.option.option,
        quantity: selection.quantity,
        modifierSelections,
        price: 0, // Priced later on repriceCartEntries
        enableUpsellPricing: selection.option.enableUpsellPricing,
      })
    ),
  ];

  const defaultComboSlotOption = getDefaultComboSlotOption(comboSlot);

  return {
    _id: comboSlot._id,
    type: CartEntryType.comboSlot,
    cartId,
    quantity: 1,
    sanityId: '',
    url,
    name: comboSlot.name.locale,
    vendorConfigs: comboSlot.vendorConfigs,
    uiPattern: comboSlot.uiPattern as ComboSlotUiPattern,
    pickerSelections: {},
    children,
    defaultComboSlotOption,
  };
};

const getCartEntryForItem = (
  item: IItem | IBaseItem,
  options?: IItemCartEntryOptions
): ICartEntry => {
  const {
    price,
    quantity,
    cartId: existingCartId,
    pathname: url = '',
    modifierSelections = [],
    pickerSelections = {},
    reorder,
    menuObjectSettings,
    enableUpsellPricing,
  } = options ?? {};
  const cartId = existingCartId || uuidv4();
  let children: ICartEntry[] = [];

  if (modifierSelections) {
    const itemOpts = modifierSelections.filter(selection => selection.itemId === item._id);
    children = composeItemOptionModifiers(itemOpts).map(selection =>
      // relate the item to the item option modifiers
      createCartEntry({
        item: selection,
      })
    );
    // check if there are modifier required for given item
    // and if there is any default modifier within the list
    if ((item as IItem)?.options?.length > 0) {
      (item as IItem).options.forEach(itemOption => {
        const numberOfModifierAlreadySelected = itemOpts.reduce(
          (prevQuantity, selectedItemOption) => {
            if (selectedItemOption._key === itemOption._key) {
              prevQuantity += selectedItemOption.modifier.quantity;
            }
            return prevQuantity;
          },
          0
        );
        const defaultItemOption = itemOption.options.find(modifierOption => modifierOption.default);
        // we will take the first default modifier and add enough to meet minAmount
        if (
          itemOption.injectDefaultSelection &&
          defaultItemOption &&
          numberOfModifierAlreadySelected < itemOption.minAmount
        ) {
          const defaultItemOptionModifierCartEntry = getCartEntryForItemOptionModifier(
            {
              ...defaultItemOption,
              quantity: itemOption.minAmount - numberOfModifierAlreadySelected,
            },
            {}
          );
          const itemOptionCartEntry = getCartEntryForItemOption(itemOption, {});
          // will override the item option modifier with the default modifier
          itemOptionCartEntry.children = [defaultItemOptionModifierCartEntry];
          children.push(itemOptionCartEntry);
        }
      });
    }
  }

  return {
    _id: item._id,
    type: CartEntryType.item,
    cartId,
    sanityId: '',
    url,
    name: item.name.locale,
    vendorConfigs: item.vendorConfigs,
    image: item.image,
    isDummyItem: item.isDummyItem,
    quantity: quantity || 1,
    children,
    pickerSelections,
    productHierarchy: item.productHierarchy,
    menuObjectSettings: item.menuObjectSettings || menuObjectSettings,
    enableUpsellPricing,
    ...(reorder && { reorder }),
    ...(price !== undefined && !isNaN(price) && { price }),
    nutritionWithModifiers: item.nutritionWithModifiers,
    productNumber: item.operationalItem?.productNumber,
  };
};

const getCartEntryForItemOption = (
  itemOption: IItemOption,
  options?: IItemOptionCartEntryOptions
): ICartEntry => {
  let children: ICartEntry[] = [];
  const { cartId: existingCartId, pathname: url = '' } = options ?? {};
  const cartId = existingCartId || uuidv4();

  children = itemOption.options.map(modifier =>
    // TODO: Review this logic. modifier.modifierMultiplier?.multiplier can be 0
    createCartEntry({
      item: !modifier.modifierMultiplier?.multiplier
        ? makeItemOptionModifier(modifier as ISanityItemOptionModifier)
        : modifier, // guarantee our itemOption modifier is always the correct shape
    })
  );

  return {
    _id: itemOption._key,
    name: itemOption.name.locale,
    cartId,
    children,
    quantity: 1,
    vendorConfigs: StaticIgnoreConfig,
    pickerSelections: {},
    sanityId: '',
    type: CartEntryType.itemOption,
    url,
  };
};

const getCartEntryForItemOptionModifier = (
  itemOptionModifier: IItemOptionModifierWithQuantity,
  options?: IItemOptionModifierCartEntryOptions
): ICartEntry => {
  const { cartId: existingCartId, pathname: url = '' } = options ?? {};
  const cartId = existingCartId || uuidv4();

  return {
    _id: itemOptionModifier._key,
    cartId,
    sanityId: '',
    children: [],
    type: CartEntryType.itemOptionModifier,
    vendorConfigs: itemOptionModifier.vendorConfigs
      ? sanitizeVendorConfigs(itemOptionModifier.vendorConfigs)
      : {},
    name: itemOptionModifier.name?.locale ?? '',
    modifierMultiplier: itemOptionModifier.modifierMultiplier,
    url,
    price: 0, // Priced later on repriceCartEntries
    quantity:
      (itemOptionModifier.quantity || itemOptionModifier.modifierMultiplier?.multiplier) ?? 1,
    pickerSelections: {},
  };
};

const getCartEntryForOfferDiscount = (
  offer: LoyaltyOffer,
  options?: IOfferCartEntryOptions
): ICartEntry => {
  const { cartId: existingCartId, pathname: url = '' } = options ?? {};
  const cartId = existingCartId || uuidv4();

  return {
    _id: offer._id,
    cartId,
    sanityId: '',
    children: [],
    type: CartEntryType.offerDiscount,
    // TODO: Review SystemwideOffers vendorConfigs worth. Maybe we could remove it from the types too
    vendorConfigs: {},
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ readonly __typename?: "LocaleB... Remove this comment to see the full error message
    name: getFirstStringInLocaleBlockContent(offer.name),
    url,
    quantity: 1,
    pickerSelections: {},
  };
};

export const createCartEntry = (options: ICreateCartEntryOptions): ICartEntry => {
  const { item } = options;
  const itemType = getType(item);
  if (!itemType) {
    throw new Error('_type or type is missing on item');
  }

  // TODO: Handle Pickers or remove them as ComboSlot options. ComboSlot options can be pickers, we currently are not handling pickers...
  switch (itemType) {
    case MenuObjectTypes.COMBO:
      return getCartEntryForCombo(item as ICombo, options as IComboCartEntryOptions);
    case MenuObjectTypes.ITEM:
      return getCartEntryForItem(item as IItem | IBaseItem, options as IItemCartEntryOptions);
    case MenuObjectTypes.COMBO_SLOT:
      return getCartEntryForComboSlot(item as IComboSlot, options as IComboSlotCartEntryOptions);
    case MenuObjectTypes.ITEM_OPTION:
      return getCartEntryForItemOption(item as IItemOption, options as IItemOptionCartEntryOptions);
    case MenuObjectTypes.ITEM_OPTION_MODIFIER:
      return getCartEntryForItemOptionModifier(
        item as IItemOptionModifierWithQuantity,
        options as IItemOptionModifierCartEntryOptions
      );
    case 'offer':
    case 'systemwideOffer':
    case 'configOffer':
      return getCartEntryForOfferDiscount(item as LoyaltyOffer, options as IOfferCartEntryOptions);
    default:
      throw new Error('somehow deriving a type from a non-defined type');
  }
};

/**
 * Updates a cart entry based on a loyalty reward.
 *
 * @param {IBackendCartEntries} cartEntry - The original cart entry.
 * @param {LoyaltyReward} reward - The loyalty reward.
 * @returns {IBackendCartEntries} The modified cart entry.
 */
export const maybeCreateCartEntryFromReward = (
  cartEntry: IBackendCartEntries,
  reward: LoyaltyReward
): IBackendCartEntries => {
  const incentive = reward.incentives?.[0];

  if (!incentive) {
    return cartEntry;
  }

  if (incentive._type === 'picker') {
    const userSelection = cartEntry.children?.find(child => child.lineId === 'loyalty');

    if (!userSelection) {
      return cartEntry;
    }

    const pickerOption = ((incentive as unknown) as IPicker).options.find(
      option => option.option._id === userSelection.sanityId
    );

    if (!pickerOption) {
      return cartEntry;
    }

    const benefitType =
      pickerOption.option._type === 'combo' ? CartEntryType.combo : CartEntryType.item;

    return {
      ...cartEntry,
      type: benefitType,
      sanityId: userSelection.sanityId,
    };
  }

  if (incentive._type === 'combo' || incentive._type === 'item') {
    return {
      ...cartEntry,
      sanityId: incentive._id,
      type: incentive._type === 'combo' ? CartEntryType.combo : CartEntryType.item,
    };
  }

  return cartEntry;
};

const isComboMarker = ({ name, lineId }: { name?: string; lineId?: string }): boolean => {
  if (!name) {
    // Loyalty markers have name null and lineId===loyalty
    // Other items might not have name, so we assume those aren't markers
    return lineId === 'loyalty';
  }
  return ['combo sides', 'combo meals', 'combo item'].includes(name.toLowerCase().trim());
};

/**
 * Determines whether a menu item is not orderable.
 *
 * Some menu items, like Toys or combo slot markers, are not orderable.
 * This check helps to exclude non-reorderable items from the reorder widget.
 *
 * @param {string} name - Item excluded from re-ordering
 * @returns {boolean} True if item is not orderable, false otherwise
 */
export const isNotOrderable = (entry: IBackendCartEntries): boolean => {
  const { name } = entry;
  const menuItemOrderingExclusions = ['toy'];

  if (!name) {
    return false;
  }

  return menuItemOrderingExclusions.includes(name.toLowerCase().trim()) || isComboMarker(entry);
};

/**
 * Creates cart entries from an offer by spreading its children, excluding specific types.
 *
 * @param {IBackendCartEntries} entry - The entry representing an offer or reward in the cart.
 * @returns {IBackendCartEntries[]} An array of cart entries created from the offer, excluding specific types.
 */
export const maybeCreateCartEntriesFromOffer = (
  entry: IBackendCartEntries
): IBackendCartEntries[] => {
  const mappedCartEntries = [];
  for (const rootLevelCartEntry of entry.children ?? []) {
    if (rootLevelCartEntry.type === CartEntryType.comboSlot) {
      // Add all valid comboSlotOptions
      for (const comboSlotCartEntry of rootLevelCartEntry.children ?? []) {
        if (!isNotOrderable(comboSlotCartEntry)) {
          mappedCartEntries.push(comboSlotCartEntry);
        }
      }
      continue;
    }
    // Regular root level entries should be added as is (generally these are offer's mainItem)
    if (!isNotOrderable(rootLevelCartEntry)) {
      mappedCartEntries.push(rootLevelCartEntry);
    }
  }
  return mappedCartEntries;
};

const composeItemOptionModifiers = (
  modifierSelections: IModifierSelection[]
): IItemOptionModifierWithQuantity[] => {
  // TODO: At some point we may have itemOptions that are duplicated for a cartEnrty, and will need to add more uniqueness to the keys
  const reducedItemOpts = modifierSelections.reduce((acc, option) => {
    if (acc[option._key]) {
      acc[option._key].options.push(makeItemOptionModifier(option.modifier));
      return acc;
    }
    const { _key, modifier, ...rest } = option;
    return {
      ...acc,
      [_key]: {
        _key,
        ...rest,
        options: [makeItemOptionModifier(modifier)],
      },
    };
  }, {});

  return Object.values(reducedItemOpts);
};

/**
 * This method will take the url from a cart entry and return the id the entry originated from
 * in the case of a resolved picker option, the id will be the picker's
 * in the case when multiple items are added from a single combo, the id will be the combo's
 *
 *
 * *NOTE* urls can come in two flavors
 * * example url 1 - /menu/picker-$pickerId?cartId=$cartId&_id=$itemId
 * * example url 2 - /menu/picker-$pickerId
 * @param {String} url
 * @returns {String} parentId
 */
export const getParentIdFromUrl = (url: string = ''): string => {
  if (!url) {
    return url;
  }

  const [pathWithParentId] = url.split('?');
  const parentIdIdx = pathWithParentId.lastIndexOf('/') + 1;

  const parentIdWithPrefix = pathWithParentId.slice(parentIdIdx);
  const prefixIdx = parentIdWithPrefix.indexOf('-') + 1;

  const rawParentId = parentIdWithPrefix.slice(prefixIdx);
  return rawParentId;
};

/**
 * Gets a list of selections modifiers/comboSlot items to display to the guest for an entry
 * Will not display the parents only the lowest level selections (combo slots and item options are skipped)
 *
 * Note: This is different from the cart
 * (https://github.com/rbilabs/ctg-whitelabel-app/blob/GBR-510-favorite-cards/workspaces/frontend/src/hooks/use-compose-description.ts)
 *  The cart will group selections by combo slot item and main item displaying them. While this one will skip those completely
 *  without showing them and just list the modifiers/items within them.
 *
 *  Also this cart hook will make and extra menu request which is not needed if you do that before rendering.
 */
export const getSelectionsListFromEntry = (
  entry: ICartEntry | null,
  child?: boolean
): { id: string; isDummyItem?: boolean; element: ReactNode; type: CartEntryType }[] => {
  const removeDuplicates = (arr: ICartEntry[]) =>
    arr.filter((value, index) => {
      return (
        index ===
        entry?.children?.findIndex(obj => {
          return obj.name === value.name;
        })
      );
    });

  // Exit early if there is no entry or the entry has no selections
  if (!entry || (!child && !entry.children?.length)) {
    return [];
  }

  // some comboSlots should be hidden from the guest
  if (
    (entry.type === CartEntryType.comboSlot && entry.uiPattern === ComboSlotUiPattern.HIDDEN) ||
    entry.uiPattern === ComboSlotUiPattern.COLLAPSED
  ) {
    return [];
  }

  if (entry.children?.length) {
    return removeDuplicates(entry.children).flatMap(childEntry =>
      getSelectionsListFromEntry(childEntry, true)
    );
  }

  return [
    {
      id: entry.cartId,
      isDummyItem: entry.isDummyItem,
      element: entry?.name ?? '',
      type: entry.type,
    },
  ];
};

export interface IRestaurantWithPos extends Omit<IRestaurant, 'pos'> {
  pos: {
    vendor: string;
  };
}

export interface ICartInput {
  cartEntries: ICartEntry[];
  serviceMode: ServiceMode;
  store: IRestaurantWithPos;
}

export function buildCartInput({ cartEntries, serviceMode, store }: ICartInput): IPriceOrderInput {
  return {
    storeAddress: buildStoreAddress(store),
    storeId: String(store.number),
    storePosId: store.posRestaurantId,
    brand: Brand.BK,
    platform: platform(),
    posVendor: store.pos.vendor as PosVendor,
    serviceMode,
    requestedAmountCents: cartEntries.reduce((price, entry) => price + priceForCartEntry(entry), 0),
    cartEntries: remappedCartForBackEnd(cartEntries),
    vatNumber: String(store.vatNumber),
  };
}

export type BuildPriceOrderInputParams = Omit<IPriceOrderParams, 'redeemReward' | 'store'> & {
  store: IRestaurantWithPos;
};

export const sanitizeSanityImage = (image: WISanityImage) => {
  // We're knowingly breaking the contract of ICartEntry and ISanityImage here
  // we can remove these once we update @rbi-ctg/menu ICartEntry and ISanityImage
  // to make these properties options
  delete image?.asset?.metadata;
};

export const sanitizeCart = (
  cartEntries: ICartEntry[],
  vendor: PosVendors | null
): ICartEntry[] => {
  return cartEntries.map(cartEntry => {
    const {
      defaultComboSlotOption,
      mainItem,
      enableUpsellPricing,
      offerVendorConfigs,
      ...cartEntryWithOmittedProps
    } = cartEntry;
    if (cartEntryWithOmittedProps.image) {
      sanitizeSanityImage(cartEntryWithOmittedProps.image as WISanityImage);
    }

    return {
      ...cartEntryWithOmittedProps,
      vendorConfigs: vendor
        ? { [vendor]: cartEntry.vendorConfigs?.[vendor] }
        : cartEntry.vendorConfigs,
      offerVendorConfigs:
        offerVendorConfigs && vendor
          ? { [vendor]: cartEntry.offerVendorConfigs?.[vendor] }
          : cartEntry.offerVendorConfigs,
      children: sanitizeCart(cartEntry.children, vendor),
    };
  });
};

const hasPosVendor = (
  options: Omit<IPriceOrderParams, 'cartEntries' | 'redeemReward'>
): options is Omit<IPriceOrderParams, 'cartEntries' | 'redeemReward' | 'store'> & {
  store: IRestaurantWithPos;
} => {
  return !!options.store.pos?.vendor;
};

export function buildPriceOrderInput({
  cartEntries,
  options,
  vendor,
}: {
  cartEntries: ICartEntry[];
  options: Omit<IPriceOrderParams, 'cartEntries' | 'redeemReward'>;
  vendor: PosVendors | null;
}): IPriceOrderInput | undefined {
  // Can't price an order if:
  //  - store is unavailable
  //  - store doesn't have a POS vendor associated
  if (!options.isStoreOpenAndAvailable || !vendor || !hasPosVendor(options)) {
    return;
  }

  const params = merge(
    {
      cartEntries: sanitizeCart(cartEntries, vendor),
    },
    options
  );

  return {
    ...buildCartInput(params),
    rewardsApplied: params.rewardsApplied,
    customerLocale: params.customerLocale,
    customerName: params.customerName,
    paymentMethod: params.cardType,
    appliedOffers: params.appliedOffers,
    requestedAmountCents: params.requestedAmountCents,
    redeemReward: false,
    vatNumber: params.store.vatNumber,
  };
}

export { buildPriceDeliveryInput } from './build-price-delivery-input';
export { buildStoreAddress } from './build-store-address';
