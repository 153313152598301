import React, { useCallback, useState } from 'react';

import { Box, Text } from '@rbilabs/universal-components';
import { capitalize, noop } from 'lodash-es';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { BackArrowContainer } from 'components/app-header/signup-mobile-header/signup-mobile-header.styled';
import CreditCardFormInputs, { getErrorMessage } from 'components/credit-card-form-inputs';
import { LayoutContainer } from 'components/layout/styled';
import { ModalSize } from 'components/modal';
import { SafetechEncryption } from 'components/payments/integrations/orbital/components/encryption';
import {
  IEncryptionError,
  IEncryptionResult,
} from 'components/payments/integrations/orbital/components/encryption/types';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { useValidateCCNumberInClientExperiment } from 'experiments/use-validate-cc-number-in-client';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useEffectOnce from 'hooks/use-effect-once';
import useErrorModal from 'hooks/use-error-modal';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { PaymentMethodsRouteParams } from 'pages/account/types';
import { useAuthContext } from 'state/auth';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  SupportedCardType,
  defaultPaymentFieldVariation,
  defaultSupportedCardTypes,
} from 'state/launchdarkly/variations';
import { useOrderContext } from 'state/order';
import { usePaymentContext } from 'state/payment';
import { CardType } from 'state/payment/types';
import { isNative } from 'utils/environment';
import logger from 'utils/logger';
import {
  getCCFormErrors,
  initialErrorState,
  initialPaymentState,
  mapDeliveryToCCFormAddress,
  onCCFormChange,
  testCardPaymentState,
} from 'utils/payment';
import { mapPaymentMethodCardTypes } from 'utils/payment/map-payment-method-card-type';
import { routes } from 'utils/routing';

import { Header, ModalContent, StyledModal } from './styled';

export const AddPaymentMethodModal: React.FC<React.PropsWithChildren<{
  onCardAdded: () => void;
}>> = ({ onCardAdded = noop }) => {
  const { user } = useAuthContext();
  const [errors, setErrors] = useState(initialErrorState);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    addPaymentMethod,
    transformPaymentValues,
    isOrbital,
    setEncryptionResult,
    isQuickPayMethodSelected,
    setIsQuickPayMethodSelected,
    setIsAddPaymentMethodSuccess,
  } = usePaymentContext();
  const paymentFieldVariations =
    useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
    defaultPaymentFieldVariation;

  const rawSupportedCardTypes: SupportedCardType[] =
    useFlag(LaunchDarklyFlag.SUPPORTED_CARD_BRANDS_VARIATIONS) || defaultSupportedCardTypes;
  const supportedCardTypes = rawSupportedCardTypes
    .map(cardType => mapPaymentMethodCardTypes(cardType))
    .filter(Boolean) as CardType[];

  const { deliveryAddress, isDelivery: isDeliveryServiceMode } = useOrderContext();
  const { goBack } = useNavigation();
  const {
    pathname,
    params: { accountToDelete },
  } = useRoute<PaymentMethodsRouteParams>();
  const isDelivery = pathname.startsWith(routes.cart) && isDeliveryServiceMode;
  const { feCountryCode: billingCountry } = useLocale();
  const isMobile = useIsMobileBreakpoint();
  const userDetailsName = user?.details.name;

  const [paymentValues, setPaymentValues] = useState(
    initialPaymentState({
      isDelivery,
      deliveryAddress,
      billingCountry,
      userDetailsName,
    })
  );
  const { formatMessage } = useIntl();
  const autoFill = useFlag(LaunchDarklyFlag.AUTO_FILL_TEST_CARD);
  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Vaulting Credit Card details',
  });
  const [isEncryptionActive, setIsEncryptionActive] = useState(false);

  const validateCCExperiment = useValidateCCNumberInClientExperiment();

  useEffectOnce(() => {
    if (autoFill) {
      setPaymentValues(testCardPaymentState({ isDelivery, paymentFieldVariations }));
    }
  });

  const handleChange = useCallback(
    (name: any, value: any) => {
      const { state, formErrors } = onCCFormChange(
        name,
        value,
        paymentValues,
        errors,
        formatMessage,
        supportedCardTypes,
        validateCCExperiment
      );
      setErrors(prevState => ({ ...prevState, ...formErrors }));
      setPaymentValues(prevState => {
        return state.billingAddressSameAsDelivery
          ? {
              ...prevState,
              ...state,
              ...mapDeliveryToCCFormAddress(deliveryAddress),
            }
          : { ...prevState, ...state };
      });
    },
    [
      paymentValues,
      errors,
      formatMessage,
      deliveryAddress,
      supportedCardTypes,
      validateCCExperiment,
    ]
  );

  const addNewCard = useCallback(
    async (event: any, encryptionResult?: IEncryptionResult) => {
      if (event) {
        event.preventDefault();
      }

      const onAddCardSuccess = () => {
        onCardAdded();
        setPaymentValues(initialPaymentState({ isDelivery, deliveryAddress }));
        if (isQuickPayMethodSelected) {
          setIsAddPaymentMethodSuccess(true);
          setIsQuickPayMethodSelected(false);
        }

        goBack();
        setIsLoading(false);
      };

      const onAddCardFailure = (paymentMethodError: Error, message: string) => {
        logger.error({ error: paymentMethodError, message });
        openErrorDialog({
          message: formatMessage({ id: 'paymentAddingError' }),
          error: paymentMethodError,
        });
        setIsLoading(false);
      };

      setIsLoading(true);
      const reshapedPaymentValues = transformPaymentValues({
        paymentValues,
        paymentFieldVariations,
      });
      reshapedPaymentValues.accountToDelete = accountToDelete;

      try {
        await addPaymentMethod(
          reshapedPaymentValues,
          {
            skipErrorDialogOnError: true,
          },
          encryptionResult
        );
        onAddCardSuccess();
      } catch (error) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        onAddCardFailure(error, 'Error adding payment method');
      }
    },
    [
      transformPaymentValues,
      paymentValues,
      paymentFieldVariations,
      accountToDelete,
      onCardAdded,
      isDelivery,
      deliveryAddress,
      isQuickPayMethodSelected,
      setIsQuickPayMethodSelected,
      goBack,
      openErrorDialog,
      formatMessage,
      addPaymentMethod,
      setIsAddPaymentMethodSuccess,
    ]
  );

  const handleOnPress = useCallback(
    async (event: any) => {
      if (event) {
        event.preventDefault();
      }
      const { hasErrors, formErrors } = getCCFormErrors(
        paymentValues,
        errors,
        formatMessage,
        paymentFieldVariations,
        billingCountry
      );

      if (hasErrors) {
        // TODO: RN - Focus on first field with errors
        const newErrorMessage = getErrorMessage({ errors, formatMessage });
        setErrorMessage(newErrorMessage);

        return setErrors(prevState => ({ ...prevState, ...formErrors, didAttemptSubmit: true }));
      }

      if (isOrbital) {
        setEncryptionResult(undefined);
        setIsLoading(true);
        return setIsEncryptionActive(true);
      }
      addNewCard(null);
    },
    [
      addNewCard,
      billingCountry,
      errors,
      formatMessage,
      isOrbital,
      paymentFieldVariations,
      paymentValues,
      setEncryptionResult,
    ]
  );

  // Change the modal header & button text if we're re-vaulting the CC
  const modalHeading = accountToDelete
    ? formatMessage({ id: 'updatePaymentMethod' })
    : formatMessage({ id: 'addPaymentMethod' });

  const modalBtnText = `${
    accountToDelete ? capitalize(formatMessage({ id: 'confirm' })) : formatMessage({ id: 'save' })
  } & ${formatMessage({ id: 'continue' })}`;

  const onEncryptionResult = (data: IEncryptionResult) => {
    setEncryptionResult(data);
    setIsEncryptionActive(false);
    addNewCard(null, data);
  };

  // Handle encryption error
  const onEncryptionError = (error: IEncryptionError) => {
    setIsEncryptionActive(false);
    setEncryptionResult(undefined);
    setIsLoading(false);
    const errorMessage = error.error;
    logger.error({ error: new Error(errorMessage), message: errorMessage });
    openErrorDialog({
      message: formatMessage({ id: 'paymentAddingError' }),
      error: new Error(errorMessage),
    });
  };

  return (
    <LayoutContainer isFullContainer={isMobile}>
      <BackArrowContainer />
      <StyledModal
        mParticleEventData={{
          modalAppearanceEventMessage: 'Add payment method',
        }}
        size={ModalSize.FULLSCREEN}
        onDismiss={goBack}
        header={
          <Header
            marginTop={isNative ? '$10' : 0}
            paddingBottom={isNative ? '$10' : 0}
            marginBottom="$0"
            marginX="auto"
            variant="headerOne"
            textAlign="center"
            borderBottom="$0"
            maxWidth={isMobile ? '4/5' : 'full'}
          >
            {modalHeading}
          </Header>
        }
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; onDismiss: () => void; ... Remove this comment to see the full error message
        allowModalResizeWithKeyboard
      >
        <ModalContent testID="add-payment-modal-heading" py="$0">
          {!!errorMessage && (
            <VisuallyHidden role="alert" accessibilityLabel="">
              <Text>{errorMessage}</Text>
            </VisuallyHidden>
          )}
          <CreditCardFormInputs
            onChange={handleChange}
            paymentValues={paymentValues}
            errors={errors}
            isDelivery={isDelivery}
          />
          <Box marginY="$4" marginX="$0">
            <ActionButton
              fullWidth
              testID="save-and-continue"
              isLoading={isLoading}
              disabled={!paymentValues.saveCard}
              onPress={handleOnPress}
            >
              {modalBtnText}
            </ActionButton>
          </Box>
        </ModalContent>
        <ErrorDialog />

        {isOrbital && isEncryptionActive ? (
          <SafetechEncryption
            cardNumber={paymentValues.cardNumber}
            cvv={paymentValues.cvv || ''}
            onResult={onEncryptionResult}
            onError={onEncryptionError}
          />
        ) : null}
      </StyledModal>
    </LayoutContainer>
  );
};
