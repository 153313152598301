/* eslint-disable max-lines */
import './icons';
import type { CreateVariants, UclThemeComponentOptions } from '../../utils/native-base/types';

const webFocusVisibleStyles = {
  boxShadow: '0 0 0 4px rgba(0, 0, 0, 0.1)',
};

export type SharedVariants = CreateVariants<{
  Alert: {
    variants: {
      another: {};
      'left-accent': {};
      outline: {};
      'outline-light': {};
      solid: {};
      subtle: {};
      'top-accent': {};
    };
  };
  Badge: {
    variants: {
      'default-outline': {};
      'default-solid': {};
      'default-subtle': {};
      'number-outline': {};
      'number-solid': {};
      'number-subtle': {};
    };
  };
  Button: {
    sizes: { lg: {}; md: {}; sm: {}; xs: {} };
    variants: {
      ghost: {};
      'ghost-reversed': {};
      outline: {};
      'outline-black': {};
      'outline-reversed': {};
      solid: {};
      'solid-reversed': {};
      utility: {};
    };
  };
  Center: {
    sizes: {
      lg: {};
      md: {};
      sm: {};
    };
  };
  CircularProgress: {
    sizes: {
      '2xl': {};
      lg: {};
      md: {};
      sm: {};
      xl: {};
      xs: {};
    };
  };
  Code: {
    variants: {
      outline: {};
      solid: {};
      subtle: {};
    };
  };
  Icon: {
    sizes: {
      '2xl': {};
      '3xl': {};
      '4xl': {};
      '5xl': {};
      '6xl': {};
      lg: {};
      md: {};
      sm: {};
      xl: {};
      xs: {};
      xxs: {};
    };
    variants: {
      accessible: {};
      account: {};
      add: {};
      addCard: {};
      amazonPay: {};
      amex: {};
      applepay: {};
      applepayNoBorder: {};
      back: {};
      bkAccount: {};
      bkCode: {};
      bkCrown: {};
      bkCrownPass: {};
      bkDaytime: {};
      bkOffers: {};
      bkOrder: {};
      bkRedeem: {};
      bkRewards: {};
      bkSaving: {};
      bkSunrise: {};
      bkUpsellPlus: {};
      bkUpsellPlusV2: {};
      calendar: {};
      camera: {};
      cancel: {};
      cart: {};
      cash: {};
      check: {};
      checkCircle: {};
      checkFill: {};
      checkMark: {};
      checkMarkWhite: {};
      collapse: {};
      crownCreditCard: {};
      curbside: {};
      defaultCard: {};
      delivery: {};
      diners: {};
      discover: {};
      drive: {};
      driveThru: {};
      edit: {};
      emptyCreditCard: {};
      error: {};
      errorFill: {};
      facebook: {};
      favorite: {};
      favoriteFill: {};
      filters: {};
      forward: {};
      googlePay: {};
      googlePayBrand: {};
      help: {};
      hotelClass: {};
      home: {};
      info: {};
      infoFill: {};
      instagram: {};
      jcb: {};
      language: {};
      laptop: {};
      list: {};
      location: {};
      locked: {};
      map: {};
      mastercard: {};
      masterpass: {};
      menu: {};
      mobile: {};
      monitor: {};
      more: {};
      moreVertical: {};
      nearMe: {};
      next: {};
      offer: {};
      paypal: {};
      plkAccount: {};
      plkDelivery: {};
      plkGiftCard: {};
      plkMenu: {};
      plkPickup: {};
      plkPickupFilled: {};
      plkRewards: {};
      plkRewardsPoints: {};
      plkRewardsProgress: {};
      plkStore: {};
      qr: {};
      qrScan: {};
      refresh: {};
      remove: {};
      removeCircle: {};
      restaurant: {};
      rewards: {};
      search: {};
      select: {};
      settings: {};
      smallCreditCardWhite: {};
      sodiumFlag: {};
      starRate: {};
      starRateFill: {};
      store: {};
      telephone: {};
      thAccount: {};
      thCanadaFlag: {};
      thHome: {};
      thMailReceived: {};
      thOffer: {};
      thOrder: {};
      thOrderFilled: {};
      thQr: {};
      thTimsCard: {};
      thUsFlag: {};
      tiktok: {};
      time: {};
      timsCard: {};
      twitter: {};
      unlocked: {};
      visa: {};
      visibility: {};
      visibilityOff: {};
      voice: {};
      warning: {};
      youtube: {};
    };
  };
  IconButton: {
    sizes: { lg: {}; md: {}; sm: {}; xs: {} };
    variants: {
      ghost: {};
      'ghost-reversed': {};
      input: {};
      outline: {};
      'outline-reversed': {};
      solid: {};
      'solid-reversed': {};
      unstyled: {};
    };
  };
  Image: {
    sizes: {
      '2xl': {};
      '2xs': {};
      full: {};
      lg: {};
      md: {};
      sm: {};
      xl: {};
      xs: {};
    };
  };
  InlineAlert: {
    sizes: {
      md: {};
      sm: {};
    };
  };
  Input: {
    sizes: {
      '2xl': {};
      lg: {};
      md: {};
      sm: {};
      xl: {};
      xs: {};
    };
    variants: {
      filled: {};
      outline: {};
      rounded: {};
      underlined: {};
      unstyled: {};
    };
  };
  PinInput: {
    sizes: {
      '2xl': {};
      lg: {};
      md: {};
      sm: {};
      xl: {};
      xs: {};
    };
  };
  Progress: {
    sizes: {
      '2xl': {};
      lg: {};
      md: {};
      sm: {};
      xl: {};
      xs: {};
    };
  };
  Spinner: {
    sizes: {
      lg: {};
      sm: {};
    };
  };
  Stack: {
    sizes: {
      '2xl': {};
      '2xs': {};
      gutter: {};
      lg: {};
      md: {};
      sm: {};
      xl: {};
      xs: {};
    };
  };
  Tag: {
    sizes: {
      lg: {};
      md: {};
      sm: {};
    };
  };
  tabs: {
    sizes: {
      lg: {};
      md: {};
      sm: {};
    };
    variants: {
      filled: {};
      'filled-outline': {};
      outline: {};
    };
  };
  tag: {
    sizes: {
      lg: {};
      md: {};
      sm: {};
    };
    variants: {
      outline: {};
      solid: {};
      subtle: {};
    };
  };
}>;

/**
 * NativeBase non-component theme options are controlled via shared primitives and tokens
 * rather than a shared native base theme object.
 */

/**
 * Shared native base theme should not use createNativeBaseThemeComponents ()
 * as extendTheme should only be called in once.
 */

export const sharedNativeBaseThemeComponents: UclThemeComponentOptions = {
  ActionsheetContent: {
    baseStyle: {
      alignItems: 'flex-start',
      _dragIndicator: {
        bg: 'token.background-handlebar',
      },
    },
  },
  ActionsheetItem: {
    baseStyle: {
      borderRadius: 4,
      _text: {
        fontFamily: 'body',
        fontSize: 16,
        fontWeight: 400,
        color: 'token.text-default',
      },
      _pressed: {
        bg: 'token.background-hover',
      },
      _hover: {
        bg: 'token.background-hover',
      },
    },
  },
  ActionsheetHeader: {
    baseStyle: {
      py: 5,
      px: 4,
      _text: {
        fontSize: 16,
        color: 'token.text-action-sheet-title',
      },
    },
  },
  ActionsheetFooter: {
    baseStyle: {
      borderRadius: 0,
      borderTopWidth: '1',
      borderTopColor: 'blackOpacity.10',
      _web: {
        style: {
          // @ts-ignore
          boxShadow: '0',
        },
      },
    },
  },
  Alert: {
    variants: {
      black: {
        marginX: {
          base: 3,
          lg: 10,
        },
        marginBottom: {
          base: 1,
          lg: 4,
        },
        bg: 'token.background-toast',
        // @ts-ignore not correctly typed
        _icon: {
          color: 'white',
        },
      },
    },
  },
  AlertDialog: {
    defaultProps: {
      _web: {
        size: 'md',
      },
    },
  },
  AlertDialogContent: {
    baseStyle: {
      borderRadius: 20,
      shadow: 0,
      bg: 'white',
      px: '6',
      py: '8',
    },
  },

  AlertDialogHeader: {
    baseStyle: {
      px: 0,
      py: 0,
      borderBottomWidth: '0',
      bg: 'white',
      _text: {
        fontSize: 'lg',
        color: 'token.text-default',
      },
    },
  },
  AlertDialogBody: {
    baseStyle: {
      paddingTop: '0',
      px: 0,
      bg: 'white',
      _text: {
        color: 'token.text-default',
      },
    },
  },
  AlertDialogFooter: {
    baseStyle: {
      p: 0,
      bg: 'white',
    },
  },
  Badge: {
    defaultProps: {
      flexDirection: 'row',
      alignItems: 'center',
      px: 2,
      py: 0.5,
      minWidth: '$6',
      height: '$6',
      justifyContent: 'center',
      _text: {
        fontSize: 12,
        fontFamily: 'body',
        fontWeight: 400,
      },
    },
    variants: {
      'default-solid': {
        borderRadius: 4,
        bg: 'token.background-badge',
        _text: { color: 'token.text-reversed' },
        color: 'token.icon-reversed',
      },
      'default-outline': {
        borderRadius: 4,
        bg: 'transparent',
        _text: { color: 'token.text-default' },
        borderColor: 'token.border-color-badge',
        color: 'token.icon-default',
        borderWidth: '1',
      },
      'default-subtle': {
        borderRadius: 4,
        bg: 'token.background-badge-subtle',
        _text: { color: 'token.text-default' },
        color: 'token.icon-default',
      },
      'number-solid': {
        borderRadius: 'full',
        bg: 'token.background-badge',
        _text: { color: 'token.text-reversed' },
        color: 'token.icon-reversed',
      },
      'number-outline': {
        borderRadius: 'full',
        bg: 'transparent',
        _text: { color: 'token.text-default' },
        borderColor: 'token.border-color-badge',
        color: 'token.icon-default',
        borderWidth: '1',
      },
      'number-subtle': {
        borderRadius: 'full',
        bg: 'token.background-badge-subtle',
        _text: { color: 'token.text-default' },
        color: 'token.icon-default',
      },
    },
  },
  ButtonGroup: {
    baseStyle: {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  Button: {
    sizes: {
      lg: {
        px: '4',
        py: '2',
        _text: {
          fontSize: 16,
        },
      },
      md: {
        px: '4',
        py: '2',
        _text: {
          fontSize: 14,
        },
      },
      sm: {
        px: '4',
        py: '2',
        _text: {
          fontSize: 12,
        },
      },
      xs: {
        px: '4',
        py: '2',
        _text: {
          fontSize: 10,
        },
      },
    },
    defaultProps: {
      size: 'lg',
      borderRadius: 'full',
      color: 'blackOpacity.70',
      _loading: {
        opacity: 1,
      },
      _text: {
        fontFamily: 'heading',
        fontWeight: 700,
      },
    },
    baseStyle: {
      _disabled: {
        backgroundColor: 'transparent',
        opacity: 1,
        _web: {
          cursor: 'not-allowed',
        },
      },
    },
    variants: {
      solid: {
        backgroundColor: 'token.background-button-primary-default',
        _text: {
          color: 'token.text-button-primary',
        },
        _hover: {
          backgroundColor: 'token.background-button-primary-hover',
        },
        _disabled: {
          bg: 'transparent',
          borderColor: 'blackOpacity.30',
          borderWidth: 1,
          _text: {
            color: 'textDisabled',
          },
          _web: {
            style: {
              // @ts-ignore
              boxShadow: 'none',
            },
          },
        },
        _pressed: {
          backgroundColor: 'token.background-button-primary-pressed',
        },
        _focusVisible: {
          _web: {
            style: {
              ...webFocusVisibleStyles,
            },
          },
        },
      },
      'solid-reversed': {
        backgroundColor: 'token.background-button-primary-default-reversed',
        _text: {
          color: 'token.text-button-primary-reversed',
        },
        _hover: {
          backgroundColor: 'token.background-button-hover-reversed',
          _text: {
            color: 'token.text-button-reversed',
          },
        },
        _disabled: {
          borderColor: 'whiteOpacity.30',
          borderWidth: 1,
          _text: {
            color: 'token.text-button-reversed',
          },
          _web: {
            style: {
              // @ts-ignore
              boxShadow: 'none',
            },
          },
        },
        _pressed: {
          backgroundColor: 'token.background-button-hover-reversed',
          _text: {
            color: 'token.text-button-reversed',
          },
        },
        _focusVisible: {
          _web: {
            style: {
              ...webFocusVisibleStyles,
            },
          },
        },
        _loading: {},
      },

      outline: {
        borderColor: 'token.border-color-button-secondary',
        _text: {
          color: 'token.text-button-secondary',
        },
        _hover: {
          backgroundColor: 'token.background-button-secondary-hover',
          borderColor: 'token.border-color-button-secondary',
        },
        _disabled: {
          borderColor: 'blackOpacity.30',
          borderWidth: 1,
          _text: {
            color: 'textDisabled',
          },
          _web: {
            style: {
              // @ts-ignore
              boxShadow: 'none',
            },
          },
          _icon: {
            color: 'errorRed',
          },
        },
        _pressed: {
          backgroundColor: 'token.background-button-secondary-pressed',
          borderColor: 'token.border-color-button-secondary',
        },
        _focusVisible: {
          backgroundColor: 'transparent',
          borderColor: 'token.border-color-button-secondary',
          _web: {
            style: {
              ...webFocusVisibleStyles,
            },
          },
        },
      },
      'outline-v2': {
        borderWidth: 1,
        borderColor: 'token.border-color-checked',
        _text: {
          color: 'token.text-button-tertiary-default',
        },
        _hover: {
          backgroundColor: 'token.background-checked',
          borderColor: 'token.border-color-checked',
        },
        _disabled: {
          borderColor: 'blackOpacity.30',
          borderWidth: 1,
          _text: {
            color: 'textDisabled',
          },
          _web: {
            style: {
              // @ts-ignore
              boxShadow: 'none',
            },
          },
          _icon: {
            color: 'errorRed',
          },
        },
        _pressed: {
          backgroundColor: 'token.background-button-secondary-pressed',
          borderColor: 'token.border-color-checked',
        },
        _focusVisible: {
          backgroundColor: 'transparent',
          borderColor: 'token.border-color-button-secondary',
          _web: {
            style: {
              ...webFocusVisibleStyles,
            },
          },
        },
      },
      'outline-reversed': {
        borderWidth: 1,
        borderColor: 'token.border-color-button-secondary-reversed',
        _text: {
          color: 'token.text-button-reversed',
        },
        _disabled: {
          borderColor: 'whiteOpacity.30',
          borderWidth: 1,
          _text: {
            color: 'token.text-button-reversed',
          },
          _web: {
            style: {
              // @ts-ignore
              boxShadow: 'none',
            },
          },
        },
        _hover: {
          backgroundColor: 'token.background-button-hover-reversed',
        },
        _pressed: {
          backgroundColor: 'token.background-button-hover-reversed',
        },
        _focusVisible: {
          _web: {
            style: {
              ...webFocusVisibleStyles,
            },
          },
        },
      },
      ghost: {
        _text: {
          color: 'token.text-button-tertiary-default',
        },
        _hover: {
          _text: {
            color: 'token.text-button-tertiary-hover',
          },
          backgroundColor: 'transparent',
        },
        _disabled: {
          _text: {
            color: 'token.text-disabled',
          },
          _web: {
            style: {
              // @ts-ignore
              boxShadow: 'none',
            },
          },
        },
        _pressed: {
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          _text: {
            color: 'token.text-button-tertiary-hover',
          },
        },
        _focusVisible: {
          backgroundColor: 'transparent',
          _web: {
            style: {
              ...webFocusVisibleStyles,
            },
          },
        },
      },
      'ghost-reversed': {
        _text: {
          color: 'token.text-button-reversed',
        },
        _disabled: {
          _text: {
            color: 'token.text-disabled-reversed',
          },
          _web: {
            style: {
              // @ts-ignore
              boxShadow: 'none',
            },
          },
        },
        _hover: {
          backgroundColor: 'token.background-button-hover-reversed',
        },
        _pressed: {
          backgroundColor: 'token.background-button-hover-reversed',
        },
        _focusVisible: {
          _web: {
            style: {
              ...webFocusVisibleStyles,
            },
          },
        },
      },
      utility: {
        width: 'full',
        backgroundColor: 'token.background-button-primary-default',
        _text: {
          color: 'token.text-button-primary',
        },
        _hover: {
          backgroundColor: 'token.background-button-primary-hover',
        },
        _disabled: {
          bg: 'transparent',

          borderColor: 'blackOpacity.30',

          borderWidth: 1,
          _text: {
            color: 'token.text-disabled',
          },
          _web: {
            style: {
              // @ts-ignore
              boxShadow: 'none',
            },
          },
        },
        _pressed: {
          backgroundColor: 'token.background-button-primary-hover',
        },
        _focusVisible: {
          _web: {
            style: {
              ...webFocusVisibleStyles,
            },
          },
        },
      },
    },
  },
  BrandLogo: {
    baseStyle: {
      padding: '4',
    },
  },
  Divider: {
    baseStyle: {
      // @ts-ignore
      bg: 'blackOpacity.10',
    },
  },
  HorizontalCarouselItem: {
    // @ts-ignore
    baseStyle: props => {
      const { unavailable } = props;
      return {
        bg: 'white',
        borderRadius: 12,
        justifyContent: 'space-between',
        // @ts-ignore
        width: ['253', '308'],
        height: unavailable ? '152' : null,
        _web: {
          height: null,
        },
        // @ts-ignore
        minHeight: ['152', '166'],
        borderColor: 'token.border-color-default',
        borderWidth: '1',
      };
    },
  },
  ScrollView: {
    defaultProps: {
      keyboardShouldPersistTaps: 'handled',
    },
  },
  Skeleton: {
    baseStyle: {
      // NB has this typed incorrectly
      // @ts-ignore
      startColor: 'token.background-skeleton-loader-start',
      // NB has this typed incorrectly
      // @ts-ignore
      endColor: 'token.background-skeleton-loader-end',
      fadeDuration: 0.1,
      speed: 1.0,
      h: '10',
      w: '100%',
    },
  },
  SkeletonText: {
    baseStyle: {
      // NB has this typed incorrectly
      // @ts-ignore
      startColor: 'token.background-skeleton-loader-start',
      // NB has this typed incorrectly
      // @ts-ignore
      endColor: 'token.background-skeleton-loader-end',
      fadeDuration: 0.1,
      w: '100%',
      speed: 1.0,
      _line: {
        h: 3,
        rounded: 'full',
      },
    },
    defaultProps: {
      lines: 3,
      space: 3,
    },
  },
  Checkbox: {
    defaultProps: {
      _text: {
        fontSize: 16,
        fontFamily: 'body',
      },
      mb: 2,
    },
    baseStyle: {
      borderWidth: 1,
      borderRadius: 'sm',
      borderColor: 'token.border-color-input',
      bg: 'transparent',
      _disabled: {
        borderColor: 'black',
        _checked: {
          borderColor: 'black',
          bg: 'black',
        },
        _text: {
          color: 'black',
        },
      },
      _focus: {
        _interactionBox: {
          bg: 'blackOpacity.5',
        },
      },
      _focusVisible: {
        _interactionBox: {
          bg: 'blackOpacity.5',
        },
      },
      _hover: {
        _interactionBox: {
          bg: 'transparent',
        },
        borderColor: 'token.border-color-hover',
      },
      _pressed: {
        _interactionBox: {
          bg: 'blackOpacity.5',
        },
      },
      _icon: {
        // @ts-ignore
        color: 'token.icon-form-checkbox',
      },
      _invalid: {
        borderColor: 'token.border-color-input',
      },
      _checked: {
        borderColor: 'token.background-checked',
        bg: 'token.background-checked',
      },
      _text: {
        color: 'token.text-default',
      },
    },
  },
  Input: {
    defaultProps: {
      pl: 4,
      pr: 4,
    },
    baseStyle: {
      // These override all Native-Base's BaseStyles so generally we need all of the properties that are
      // defined
      selectionColor: 'blackOpacity.30',
      bg: 'white',
      borderColor: 'token.border-color-input',
      borderRadius: 'sm',
      borderWidth: '1',
      color: 'token.text-default',
      fontFamily: 'body',
      overflow: 'hidden',
      p: '2',
      placeholderTextColor: 'token.text-default',
      _disabled: {
        // @ts-ignore opacity missing from type :\
        opacity: '100',
        bg: 'token.background-input-disabled',
        placeholderTextColor: 'token.text-disabled',
        color: 'token.text-disabled',
      },
      _invalid: {
        borderColor: 'token.border-color-error',
      },
      _focus: {
        borderColor: 'token.border-color-focus',
      },
      _hover: {
        bg: 'transparent',
      },
    },
    variants: {
      unstyled: {
        // overwrite styles from base style to give 'unstyled' look
        // this variant is used as a subcomponent in other components
        // such as the Select
        bg: 'transparent',
        borderWidth: 1,
        borderColor: 'transparent',
        _disabled: {
          bg: 'transparent',
        },
        _invalid: {
          borderColor: 'transparent',
        },
        _focus: {
          borderColor: 'transparent',
        },
      },
      outline: {
        // Inherit all the styles from base styles
      },
    },
  },
  Icon: {
    defaultProps: {
      size: 6,
      // @ts-ignore NB is not typing this correctly
      color: 'token.icon-default',
    },
    variants: {
      accessible: {
        name: 'mi:accessible',
      },
      account: {
        name: 'mi:account-circle',
      },
      add: {
        name: 'mi:add',
      },
      addCard: {
        name: 'custom:AddCard',
      },
      amazonPay: {
        name: 'custom:AmazonPay',
      },
      amex: {
        name: 'custom:Amex',
      },
      applepay: {
        name: 'custom:Applepay',
      },
      applepayNoBorder: {
        name: 'custom:ApplepayNoBorder',
      },
      back: {
        name: 'custom:Back',
      },
      bkAccount: {
        name: 'custom:BkAccount',
      },
      bkCode: {
        name: 'custom:BkCode',
      },
      bkCrown: {
        name: 'custom:BkCrown',
      },
      bkCrownPass: {
        name: 'custom:BkCrownPass',
      },
      bkDaytime: {
        name: 'custom:BkDaytime',
      },
      bkOffers: {
        name: 'custom:BkOffers',
      },
      bkOrder: {
        name: 'custom:BkOrder',
      },
      bkRedeem: {
        name: 'mi:wallet-giftcard',
      },
      bkRemove: {
        name: 'custom:Remove',
      },
      bkRewards: {
        name: 'custom:BkRewards',
      },
      bkSaving: {
        name: 'custom:BkSaving',
      },
      bkSunrise: {
        name: 'custom:BkSunrise',
      },
      bkUpsellPlus: {
        name: 'custom:BkUpsellPlus',
      },
      bkUpsellPlusV2: {
        name: 'custom:BkUpsellPlusV2',
      },
      calendar: {
        name: 'custom:CalendarToday',
      },
      camera: {
        name: 'mi:camera-alt',
      },
      cancel: {
        name: 'mi:close',
      },
      cart: {
        name: 'mi:shopping-bag',
      },
      cash: {
        name: 'custom:Cash',
      },
      cashNoBorder: {
        name: 'custom:CashNoBorder',
      },
      cashApp: {
        name: 'custom:CashApp',
      },
      cashAppLogoMark: {
        name: 'custom:CashAppLogoMark',
      },
      cashAppNoBorder: {
        name: 'custom:CashAppNoBorder',
      },
      cashAppWhiteBorder: {
        name: 'custom:CashAppWhiteBorder',
      },
      collapse: {
        name: 'mi:keyboard-arrow-up',
      },
      crownCreditCard: {
        name: 'custom:CrownCreditCard',
      },
      crownCreditCardNoBorder: {
        name: 'custom:CrownCreditCardNoBorder',
      },
      check: {
        name: 'mi:check',
      },
      checkCircle: {
        name: 'mi:check-circle-outline',
      },
      checkFill: {
        name: 'custom:CheckFill',
      },
      checkMark: {
        name: 'custom:CheckMark',
      },
      checkMarkWhite: {
        name: 'custom:CheckMarkWhite',
      },
      curbside: {
        name: 'custom:Curbside',
      },
      defaultCard: {
        name: 'mi:payment',
      },
      delivery: {
        name: 'mi:delivery-dining',
      },
      diners: {
        name: 'custom:Diners',
      },
      dinersNoBorder: {
        name: 'custom:DinersNoBorder',
      },
      discover: {
        name: 'custom:Discover',
      },
      discoverNoBorder: {
        name: 'custom:DiscoverNoBorder',
      },
      drive: {
        name: 'mi:drive-eta',
      },
      driveThru: {
        name: 'custom:DriveThru',
      },
      duplicateItem: {
        name: 'custom:DuplicateItem',
      },
      edit: {
        name: 'mi:edit',
      },
      emptyCreditCard: {
        name: 'custom:EmptyCreditCard',
      },
      error: {
        name: 'mi:error-outline',
      },
      errorV2: {
        name: 'custom:Error',
      },
      errorFill: {
        name: 'mi:error',
      },
      facebook: {
        name: 'custom:Facebook',
      },
      favorite: {
        name: 'mi:favorite-outline',
      },
      favoriteFill: {
        name: 'mi:favorite',
      },
      filters: {
        name: 'custom:Filters',
      },
      forward: {
        name: 'custom:Next',
      },
      giftCard: {
        name: 'custom:GiftCard',
      },
      giftCardNoBorder: {
        name: 'custom:GiftCardNoBorder',
      },
      googlePay: {
        name: 'custom:GooglePay',
      },
      googlePayBrand: {
        name: 'custom:GooglePayBrand',
      },
      googlePayNoBorder: {
        name: 'custom:GooglePayNoBorder',
      },
      help: {
        name: 'mi:help-outline',
      },
      home: {
        name: 'mi:home',
      },
      hotelClass: {
        name: 'custom:HotelClass',
      },
      info: {
        name: 'mi:info-outline',
      },
      infoFill: {
        name: 'mi:info',
      },
      instagram: {
        name: 'custom:Instagram',
      },
      jcb: {
        name: 'custom:Jcb',
      },
      jcbNoBorder: {
        name: 'custom:JcbNoBorder',
      },
      language: {
        name: 'mi:language',
      },
      laptop: {
        name: 'mi:laptop',
      },
      list: {
        name: 'mi:list',
      },
      location: {
        name: 'mi:location-on',
      },
      locked: {
        name: 'mi:lock',
      },
      map: {
        name: 'custom:MapOutline',
      },
      mastercard: {
        name: 'custom:Mastercard',
      },
      masterpass: {
        name: 'custom:Masterpass',
      },
      menu: {
        name: 'mi:menu',
      },
      mobile: {
        name: 'custom:Mobile',
      },
      monitor: {
        name: 'mi:monitor',
      },
      more: {
        name: 'mi:more-horiz',
      },
      moreVertical: {
        name: 'mi:more-vert',
      },
      nearMe: {
        name: 'mi:near-me',
      },
      next: {
        name: 'mi:navigate-next',
      },
      offer: {
        name: 'mi:local-offer',
      },
      paypal: {
        name: 'custom:PayPal',
      },
      paypalNoBorder: {
        name: 'custom:PayPalNoBorder',
      },
      qr: {
        name: 'mi:qr-code',
      },
      qrScan: {
        name: 'mi:qr-code-scanner',
      },
      refresh: {
        name: 'mi:refresh',
      },
      remove: {
        name: 'mi:remove',
      },
      removeCircle: {
        name: 'mi:remove-circle-outline',
      },
      restaurant: {
        name: 'mi:restaurant',
      },
      search: {
        name: 'mi:search',
      },
      select: {
        name: 'mi:keyboard-arrow-down',
      },
      settings: {
        name: 'mi:settings',
      },
      smallCreditCardWhite: {
        name: 'custom:SmallCreditCardWhite',
      },
      smallGiftCardWhite: {
        name: 'custom:SmallGiftCardWhite',
      },
      sodiumFlag: {
        name: 'custom:SodiumFlag',
      },
      starRate: {
        name: 'mi:star-border',
      },
      starRateFill: {
        name: 'mi:star',
      },
      store: {
        name: 'mi:store',
      },
      telephone: {
        name: 'mi:phone',
      },
      tiktok: {
        name: 'custom:Tiktok',
      },
      time: {
        name: 'mi:access-time',
      },
      twitter: {
        name: 'custom:Twitter',
      },
      unlocked: {
        name: 'mi:lock-open',
      },
      venmo: {
        name: 'custom:Venmo',
      },
      venmoNoBorder: {
        name: 'custom:VenmoNoBorder',
      },
      venmoLogo: {
        name: 'custom:VenmoLogo',
      },
      visa: {
        name: 'custom:Visa',
      },
      visaNoBorder: {
        name: 'custom:VisaNoBorder',
      },
      visibility: {
        name: 'mi:visibility',
      },
      visibilityOff: {
        name: 'mi:visibility-off',
      },
      voice: {
        name: 'mi:keyboard-voice',
      },
      walmartLogo: {
        name: 'custom:WalmartLogo',
      },
      whiteEmptyCreditCard: {
        name: 'custom:WhiteEmptyCreditCard',
      },
      warning: {
        name: 'mi:warning',
      },
      youtube: {
        name: 'custom:Youtube',
      },
    },
  },
  IconButton: {
    defaultProps: {
      borderRadius: 'full',
      size: 'sm',
      // TODO: change default variant to "solid" after it has been implemented in WL
      variant: 'unstyled',
    },
    sizes: {
      lg: {
        p: '$2',
        _icon: {
          size: '10',
        },
      },
      md: {
        p: '$2',
        _icon: {
          size: '8',
        },
      },
      sm: {
        p: '$2',
        _icon: {
          size: '6',
        },
      },
      xs: {
        p: '$2',
        _icon: {
          size: '4',
        },
      },
    },
    variants: {
      input: {
        padding: 0,
        mr: 3,
        borderRadius: 'full',
        _hover: {
          bg: 'blackOpacity.5',
          _icon: {
            // @ts-ignore
            color: 'token.icon-default',
          },
        },
        _pressed: {
          bg: 'blackOpacity.5',
        },
        _focus: {
          bg: 'blackOpacity.10',
        },
        _icon: {
          // @ts-ignore
          color: 'blackOpacity.30',
        },
      },
      // @ts-ignore
      solid: props => {
        const { isDisabled } = props;
        return {
          bg: isDisabled ? 'blackOpacity.30' : 'token.background-button-primary-default',
          _icon: {
            // @ts-ignore
            color: isDisabled ? 'token.icon-disabled' : 'token.icon-button-primary',
          },
          _hover: {
            bg: 'token.background-button-primary-hover',
          },
          _pressed: {
            bg: 'token.background-button-primary-pressed',
          },
          _focus: {
            bg: isDisabled ? 'blackOpacity.30' : 'token.background-button-primary-default',
          },
          _focusVisible: {
            bg: 'token.background-button-primary-default',
            _web: {
              style: {
                // @ts-ignore
                boxShadow: '0 0 0 4px rgba(0,0,0,0.1)',
              },
            },
          },
        };
      },
      // @ts-ignore
      'solid-reversed': props => {
        const { isDisabled } = props;
        return {
          bg: isDisabled ? 'whiteOpacity.30' : 'token.background-button-primary-default-reversed',
          _icon: {
            // @ts-ignore
            color: isDisabled
              ? 'token.icon-disabled-reversed'
              : 'token.icon-button-primary-reversed',
          },
          _hover: {
            bg: 'token.background-button-primary-hover-reversed',
            _icon: {
              // @ts-ignore
              color: 'token.icon-button-reversed',
            },
          },
          _pressed: {
            bg: 'token.background-button-primary-pressed-reversed',
            _icon: {
              // @ts-ignore
              color: 'token.icon-button-reversed',
            },
          },
          _focus: {
            bg: isDisabled ? 'whiteOpacity.30' : 'token.background-button-primary-pressed-reversed',
            _icon: {
              // @ts-ignore
              color: isDisabled ? 'token.icon-disabled-reversed' : 'token.icon-button-reversed',
            },
          },
          _focusVisible: {
            bg: 'token.background-button-primary-pressed-reversed',
            _web: {
              style: {
                // @ts-ignore
                boxShadow: '0 0 0 4px rgba(255, 255, 255, 0.3)',
              },
            },
          },
        };
      },
      // @ts-ignore
      outline: props => {
        const { isDisabled } = props;
        return {
          borderColor: isDisabled
            ? 'token.border-color-disabled'
            : 'token.border-color-button-secondary',
          _icon: {
            // @ts-ignore
            color: isDisabled ? 'token.icon-disabled' : 'token.icon-button-secondary',
          },
          _hover: {
            bg: 'token.background-button-secondary-hover',
          },
          _pressed: {
            bg: 'token.background-button-secondary-pressed',
          },
          _focus: {
            bg: isDisabled ? 'transparent' : 'token.background-button-secondary-pressed',
          },
          _focusVisible: {
            bg: 'token.background-button-secondary-pressed',
            _web: {
              style: {
                // @ts-ignore
                boxShadow: '0 0 0 4px rgba(0,0,0,0.1)',
              },
            },
          },
        };
      },
      // @ts-ignore
      'outline-reversed': props => {
        const { isDisabled } = props;
        return {
          borderWidth: 1,
          borderColor: isDisabled
            ? 'token.border-color-disabled-reversed'
            : 'token.border-color-button-secondary-reversed',
          _icon: {
            // @ts-ignore
            color: isDisabled ? 'token.icon-disabled-reversed' : 'token.icon-button-reversed',
          },
          _hover: {
            bg: 'token.background-button-secondary-hover-reversed',
          },
          _pressed: {
            bg: 'token.background-button-secondary-pressed-reversed',
          },
          _focus: {
            bg: isDisabled ? 'transparent' : 'token.background-button-secondary-hover-reversed',
          },
          _focusVisible: {
            bg: 'token.background-button-secondary-hover-reversed',
            _web: {
              style: {
                // @ts-ignore
                boxShadow: '0 0 0 4px rgba(255, 255, 255, 0.3)',
              },
            },
          },
        };
      },
      // @ts-ignore
      ghost: props => {
        const { isDisabled } = props;
        return {
          _icon: {
            // @ts-ignore
            color: isDisabled ? 'token.icon-disabled' : 'token.icon-button-tertiary-default',
          },
          _hover: {
            bg: 'transparent',
            _icon: {
              // @ts-ignore
              color: 'token.icon-button-tertiary-hover',
            },
          },
          _pressed: {
            bg: 'transparent',
            _icon: {
              // @ts-ignore
              color: 'token.icon-button-tertiary-hover',
            },
          },
          _focus: {
            bg: 'transparent',
            _icon: {
              // @ts-ignore
              color: 'token.icon-button-tertiary-hover',
            },
          },
          _focusVisible: {
            bg: 'transparent',
            _web: {
              style: {
                // @ts-ignore
                boxShadow: '0 0 0 4px rgba(0,0,0,0.1)',
              },
            },
          },
        };
      },
      // @ts-ignore
      'ghost-reversed': props => {
        const { isDisabled } = props;
        return {
          _icon: {
            // @ts-ignore
            color: isDisabled ? 'token.icon-disabled-reversed' : 'token.icon-button-reversed',
          },
          _hover: {
            bg: 'token.background-button-secondary-hover-reversed',
          },
          _pressed: {
            bg: 'token.background-button-secondary-pressed-reversed',
          },
          _focus: {
            bg: 'token.background-button-secondary-hover-reversed',
          },
          _focusVisible: {
            bg: 'token.background-button-secondary-hover-reversed',
            _web: {
              style: {
                // @ts-ignore
                boxShadow: '0 0 0 4px rgba(255, 255, 255, 0.3)',
              },
            },
          },
        };
      },
    },
  },
  MarketingCarouselItem: {
    baseStyle: {
      bg: 'transparent',
      borderRadius: 12,
      alignItems: 'center',
      // @ts-ignore
      width: ['253', '276'],
    },
  },
  Radio: {
    defaultProps: {
      _text: {
        fontSize: 15,
      },
      // @ts-ignore
      _icon: {
        color: 'token.icon-form-checkbox',
      },
      mb: 2,
    },
    baseStyle: () => ({
      borderWidth: 1,
      borderColor: 'token.border-color-input',
      bg: 'transparent',
      _disabled: {
        borderColor: 'black',
        _checked: {
          borderColor: 'black',
          bg: 'black',
        },
        _text: {
          color: 'black',
        },
      },
      _checked: {
        borderColor: 'token.background-checked',
        bg: 'token.background-checked',
        _interactionBox: {
          borderColor: 'blackOpacity.5',
        },
      },
      _hover: {
        borderColor: 'token.border-color-hover',
        _interactionBox: {
          bg: 'transparent',
        },
      },
      _focus: {
        _interactionBox: {
          bg: 'blackOpacity.5',
        },
      },
      _focusVisible: {
        _interactionBox: {
          bg: 'blackOpacity.5',
        },
      },
      _pressed: {
        _interactionBox: {
          bg: 'blackOpacity.5',
        },
      },
      _text: {
        color: 'token.text-default',
        fontFamily: 'body',
      },
      _invalid: {
        borderColor: 'token.border-color-input',
      },
    }),
  },
  RewardsBadge: {
    defaultProps: {
      flexDirection: 'row',
      alignItems: 'center',
      px: 2,
      py: 0.5,
      minWidth: '$6',
      height: '$6',
      justifyContent: 'center',
      borderRadius: 4,
      _text: {
        fontSize: 12,
        fontFamily: 'heading',
        fontWeight: 400,
      },
    },
    baseStyle: props => {
      const { isLocked } = props;
      return {
        bg: isLocked ? 'token.background-badge-rewards-locked' : 'token.background-badge-rewards',
        color: isLocked ? 'token.icon-rewards' : 'token.icon-rewards-locked',
        _text: {
          color: isLocked ? 'token.text-rewards' : 'token.text-rewards-locked',
        },
      };
    },
  },
  FormControl: {
    baseStyle: {
      mb: 6,
    },
  },
  FormControlErrorMessage: {
    baseStyle: {
      _text: {
        fontSize: 11,
        color: 'token.text-error',
      },
    },
  },
  FormControlHelperText: {
    baseStyle: {
      _text: {
        fontSize: 11,
        color: 'token.text-default',
      },
    },
  },
  FormControlLabel: {
    baseStyle: {
      _text: {
        fontSize: 16,
        fontFamily: 'heading',
        fontWeight: 400,
      },
    },
  },
  Select: {
    baseStyle: {
      borderRadius: 'sm',
      paddingRight: '0',
      borderWidth: '1',
      minHeight: '12',

      // @TODO: Temporary styles -- waiting on ActionSheet design
      _item: {
        height: '12',
        borderRadius: 'none',
        px: '2',
        py: '0',
        borderTopWidth: '1',
        borderColor: 'token.border-color-input',
        _text: {
          color: 'token.text-default',
          fontSize: 'xs',
          lineHeight: 'xs',
          fontFamily: 'body',
        },
      },
      // End Temporary styles
    },
  },
  Spinner: {
    defaultProps: {
      size: 'lg',
    },
  },
  Switch: {
    baseStyle: {
      _android: {
        style: {
          transform: [{ scale: 1.3 }],
        },
      },
      _ios: {
        style: {
          transform: [{ scale: 0.8 }],
        },
      },
      _web: {
        style: {
          transform: [{ scale: 1.16 }],
        },
      },
      _disabled: {
        opacity: 1,
        offTrackColor: 'token.background-switch-track-disabled',
        onTrackColor: 'token.background-switch-track-disabled',
        onThumbColor: 'token.background-switch-thumb',
        offThumbColor: 'token.background-switch-thumb',
      },
      // @ts-ignore
      onThumbColor: 'token.background-switch-thumb',
      // @ts-ignore
      offThumbColor: 'token.background-switch-thumb',
      // @ts-ignore
      offTrackColor: 'token.background-switch-track',
      // @ts-ignore
      onTrackColor: 'token.background-active',
    },
  },
  VerticalCarouselItem: {
    baseStyle: {
      bg: ['white', 'transparent'],
      borderRadius: 12,
      alignItems: 'center',
      borderColor: 'token.border-color-default',
      borderWidth: ['1', '1', '0'],
    },
  },
};
