import { FC, PropsWithChildren, useCallback, useMemo } from 'react';

import { ContentCard } from '@braze/react-native-sdk';
import { useIntl } from 'react-intl';

import { useNavigation } from 'hooks/navigation/use-navigation';
import useBrazeContext from 'state/braze/hooks/use-braze-context';
import { ContentCardTypes } from 'state/braze/types';
import { actions, useAppDispatch } from 'state/global-state';

import {
  ButtonContainer,
  CaptionedCardContainer,
  CaptionedCardContent,
  CaptionedCardImage,
  CardButton,
  CardDescription,
  CardTitle,
  ContentCardContainer,
  StyledLink,
} from './styled';

type CaptionedContentCardProps = {
  card: ContentCard & {
    type: typeof ContentCardTypes.CAPTIONED;
  };
  showTermsLink?: boolean;
  onShowTerms?(): void;
};

export const CaptionedContentCard: FC<PropsWithChildren<CaptionedContentCardProps>> = ({
  card,
  showTermsLink,
  onShowTerms,
}) => {
  const { logContentCardClicked } = useBrazeContext();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();

  const isChallenge = useMemo(() => showTermsLink && !!onShowTerms, [onShowTerms, showTermsLink]);

  const handleClickCta = useCallback(() => {
    logContentCardClicked(card);
    navigate(card.url || '');
    dispatch(actions.loyalty.closeInbox());
  }, [card, dispatch, logContentCardClicked, navigate]);

  return (
    <ContentCardContainer>
      <CaptionedCardContainer>
        <CaptionedCardImage
          alt={card.title}
          source={{ uri: card.image }}
          style={{ aspectRatio: card.imageAspectRatio }}
        />
        <CaptionedCardContent>
          <CardTitle>{card.title}</CardTitle>
          <CardDescription>{card.cardDescription}</CardDescription>
          <ButtonContainer>
            {!!card.url && (
              <CardButton onPress={handleClickCta} isChallenge={isChallenge}>
                {card.domain}
              </CardButton>
            )}
            {isChallenge && (
              <StyledLink onPress={onShowTerms}>{formatMessage({ id: 'viewTerms' })}</StyledLink>
            )}
          </ButtonContainer>
        </CaptionedCardContent>
      </CaptionedCardContainer>
    </ContentCardContainer>
  );
};
