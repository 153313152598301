import { FC, PropsWithChildren, useContext } from 'react';

import useEffectOnce from 'hooks/use-effect-once';
import { BrazeContext } from 'state/braze';
import { ContentCardTypes } from 'state/braze/types';
import { ContentCard } from 'utils/braze';

import { CaptionedContentCard } from './captioned';
import { ChallengeContentCard } from './challenge';
import { ClassicContentCard } from './classic';
import { ImageOnlyContentCard } from './image-only';

type CustomContentCardProps = {
  card: ContentCard;
};

export const CustomContentCard: FC<PropsWithChildren<CustomContentCardProps>> = ({ card }) => {
  const { logContentCardImpression } = useContext(BrazeContext);

  /**
   * Control Cards should'n be displayed, but we need to handle them when logging impressions
   * in an A/B test. These cards are blank, and while they aren’t seen by users, we should still
   * log impressions in order to compare how they perform against non-Control cards.
   */
  useEffectOnce(() => {
    if (card.isControl) {
      logContentCardImpression(card.id);
    }
  });

  if (card.isControl) {
    return null;
  }

  switch (card.type) {
    case ContentCardTypes.CLASSIC:
      return <ClassicContentCard card={card} />;
    case ContentCardTypes.CAPTIONED:
      return card.extras.challenge === 'true' ? (
        <ChallengeContentCard card={card} />
      ) : (
        <CaptionedContentCard card={card} />
      );
    case ContentCardTypes.IMAGE_ONLY:
      return <ImageOnlyContentCard card={card} />;
    default:
      return null;
  }
};
