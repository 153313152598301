import { useIntl } from 'react-intl';

import { IRestaurant } from '@rbi-ctg/store';
import { useMemoWithCompare } from 'hooks/use-memo-with-compare';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import {
  ExcludeLegacyServiceModes,
  ServiceMode,
  ServiceModeStatus,
} from 'state/service-mode/types';

export const serviceModeToIntlId: Record<ExcludeLegacyServiceModes, string> = {
  [ServiceMode.CURBSIDE]: 'curbside',
  [ServiceMode.DELIVERY]: 'delivery',
  [ServiceMode.DRIVE_THRU]: 'driveThru',
  [ServiceMode.EAT_IN]: 'eatIn',
  [ServiceMode.TAKEOUT]: 'takeOut',
};

// Store the order and which service modes will display in the text.
const serviceModeOrder: ServiceMode[] = [
  ServiceMode.DRIVE_THRU,
  ServiceMode.TAKEOUT,
  ServiceMode.EAT_IN,
  ServiceMode.CURBSIDE,
];

interface UseServiceModeTextOptions {
  restaurant: IRestaurant;
}

export const isCapable = (serviceModeStatus: ServiceModeStatus) => (
  serviceMode: ServiceMode
): boolean => {
  const status = serviceModeStatus[serviceMode];

  if (!status) {
    return false;
  }

  return status.capable;
};

export const useServiceModeText = ({
  restaurant,
}: UseServiceModeTextOptions): string | undefined => {
  const { formatMessage } = useIntl();
  const { serviceModeStatus } = useServiceModeStatus(restaurant);

  const serviceModes = serviceModeOrder.filter(isCapable(serviceModeStatus));

  return useMemoWithCompare(() => {
    if (!serviceModes.length) {
      return;
    }

    return serviceModes
      .map(serviceMode => formatMessage({ id: serviceModeToIntlId[serviceMode] }))
      .join(', ');
  }, [serviceModes]);
};
